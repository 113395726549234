import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';
import {isEqual} from 'lodash';
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';
import {animate, animateChild, group, query, state, style, transition, trigger} from '@angular/animations';

import {OverlayContent, OverlayInstance, OverlayOptions, OverlayPlacement, OverlayShowOptions} from '../overlay.types';
import {ElementOverlayOrigin, OverlayOrigin, PointerOverlayOrigin} from '../overlay-origin';
import {subscriptions} from '../../../services/subscriptions';
import {AbstractScreenSizeService} from '../../../services/abstract-screen-size.service';
import {Boundaries, Dimensions, Point, Position, Size} from '../../../types';
import {toggleElement} from '../../../utils/dom/toggle-element';
import {convertPlacement, flipPlacement, isComponentContent, isTemplateContent} from '../overlay.helpers';
import {skipInitialCall} from '../../../utils/skip-initial-call';
import {getClosestScrollableContainer} from '../../../utils/dom/get-closest-scrollable-container';
import {DynamicRect} from '../../../utils/dynamic-rect';
import {AbstractDialogComponent} from '../../dialog/abstract-dialog.component';
import {DialogContentComponent} from '../../dialog/dialog.types';

/**
 * Component used as a container for Overlay service. Inserts dynamically component or templateRef inside itself.
 * Position itself relative to the origin element.
 */
@Component({
  selector: 'w-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('leaveInOut', [
      state('leaveIn', style({transform: 'translateY(0)', opacity: 1})),
      transition(
        ':enter',
        group([
          query('.overlay_mobile', [style({transform: 'translateY(100%)'}), animate('.2s ease')], {
            optional: true,
          }),
          query('.overlay-background', [style({opacity: 0}), animate('.2s ease')], {optional: true}),
          // Invoke only enabled animation, because animateChild animates even disabled animations.
          query('.overlay .ng-trigger:not(.ng-animate-disabled)', animateChild(), {optional: true}),
        ]),
      ),
      transition(
        ':leave',
        group([
          query('.overlay_mobile', [animate('.2s ease', style({transform: 'translateY(100%)'}))], {
            optional: true,
          }),
          query('.overlay-background', [animate('.2s ease', style({opacity: 0}))], {optional: true}),
          // Invoke only enabled animation, because animateChild animates even disabled animations.
          query('.overlay .ng-trigger:not(.ng-animate-disabled)', animateChild(), {optional: true}),
        ]),
      ),
    ]),
  ],
})
export class OverlayComponent<TContentProps extends object = any> implements OnInit, AfterViewInit, OnDestroy {
  @Input({required: true}) content: OverlayContent<
    TContentProps,
    Partial<TContentProps> & {$implicit: OverlayInstance<TContentProps>}
  >;

  @Input({required: true}) opts: OverlayOptions<TContentProps>;
  @Input({required: true}) overlay: OverlayInstance<TContentProps>;
  @Input({required: true}) overlayContainer: HTMLElement;

  @HostBinding('@leaveInOut') leaveInOutAnimation = true;

  @ViewChild('contentContainer', {static: true, read: ViewContainerRef}) contentContainer: ViewContainerRef;
  @ViewChild('overlayBackground', {static: false}) overlayBackgroundEl: ElementRef<HTMLElement>;
  @ViewChild('overlayContent', {static: true}) overlayContentEl: ElementRef<HTMLElement>;

  hostElement: HTMLElement;
  element: HTMLElement;
  origin: OverlayOrigin;
  fitStrategy: OverlayShowOptions['fitStrategy'];
  currentPlacement$: Observable<OverlayPlacement | null>;

  private currentPlacementSubject = new BehaviorSubject<OverlayPlacement | null>(null);
  private placement: OverlayPlacement | null = null;
  private preferredPlacement: OverlayPlacement | null = null;
  private originParents?: Element[];

  private isTouching = false;
  private touchClientY: number;
  private touchStartTimeStamp: number;
  private scrollLockedElems: HTMLElement[] | null = null;
  private originResizeObserver: ResizeObserver | null = null;

  private contentComponentRef: ComponentRef<TContentProps>;
  private contentTemplateContext: Partial<TContentProps> & {$implicit: OverlayInstance<TContentProps>};
  private subs = subscriptions();

  constructor(
    private hostElemRef: ElementRef<HTMLElement>,
    private ngZone: NgZone,
    private screenSize: AbstractScreenSizeService,
  ) {
    this.hostElement = this.hostElemRef.nativeElement;

    this.currentPlacement$ = this.currentPlacementSubject.pipe(distinctUntilChanged(isEqual));
  }

  ngOnInit() {
    this.element = this.overlayContentEl.nativeElement;
    this.fitStrategy = this.opts.fitStrategy || 'shift';

    this.setOrigin(this.opts.origin);
    this.updatePlacement(this.opts.placement);

    this.attachContent();
  }

  ngAfterViewInit() {
    if (this.connected) {
      this.attachScrollHandlers();
      this.ngZone.runOutsideAngular(() => {
        window.addEventListener('resize', this.updatePosition);
      });
    }

    this.subs.add(this.screenSize.change.subscribe(this.updateLayout));
  }

  ngOnDestroy() {
    window.removeEventListener('resize', this.updatePosition);
    this.setOrigin(null);
    this.detachScrollHandlers();
    this.detachScrollTouchMoveHandler();
    this.toggleBodyScroll(true);
  }

  get connected(): boolean {
    return Boolean(this.origin && this.placement);
  }

  get isMobileView(): boolean {
    return Boolean(this.opts.supportMobileView && this.screenSize.current.name === 'phone');
  }

  get isAbsolutelyPositioned(): boolean {
    return Boolean(this.opts.attachTo);
  }

  get overlayContentHeight(): number {
    return this.overlayContentEl.nativeElement.offsetHeight;
  }

  get hasBackground(): boolean {
    return this.opts.withBackground || this.isMobileView;
  }

  get contentComponent(): DialogContentComponent | undefined {
    const dialogContentComponent = this.contentComponentRef?.instance as AbstractDialogComponent;

    return dialogContentComponent?.contentComponent;
  }

  updateOrigin(origin: OverlayOrigin) {
    if (this.connected) {
      this.detachScrollHandlers();
      this.setOrigin(origin);
      this.attachScrollHandlers();
      this.position();
    }
  }

  close() {
    this.overlay.hide();
  }

  @HostListener('touchstart', ['$event'])
  // Can't use `TouchEvent` here because of `ReferenceError: TouchEvent is not defined` in Firefox in unit tests
  handleTouchStart(event: Event) {
    if (!this.isMobileView) {
      return;
    }

    this.isTouching = true;

    this.touchClientY = (event as TouchEvent).targetTouches[0].clientY;
    this.touchStartTimeStamp = event.timeStamp;

    this.overlayContentEl.nativeElement.style.transition = 'none';
    this.overlayBackgroundEl.nativeElement.style.transition = 'none';

    this.ngZone.runOutsideAngular(() => {
      this.attachTouchMoveHandler();
    });
  }

  @HostListener('touchend', ['$event'])
  // Can't use `TouchEvent` here because of `ReferenceError: TouchEvent is not defined` in Firefox in unit tests
  handleTouchEnd(event: Event) {
    if (!this.isMobileView) {
      return;
    }

    const deltaY = this.touchClientY - (event as TouchEvent).changedTouches[0].clientY;
    const areaToCloseHeight = this.overlayContentHeight / 2;

    // Close on swipe down or on reaching close area
    if ((event.timeStamp - this.touchStartTimeStamp < 300 && deltaY < -10) || areaToCloseHeight - deltaY * -1 < 0) {
      this.close();

      return;
    }

    this.isTouching = false;
    this.detachScrollTouchMoveHandler();

    this.overlayBackgroundEl.nativeElement.style.opacity = '';
    this.overlayBackgroundEl.nativeElement.style.transition = '';
    this.overlayContentEl.nativeElement.style.transform = '';
    this.overlayContentEl.nativeElement.style.transition = '';
  }

  updateContentProps(props: Partial<TContentProps>) {
    if (this.contentTemplateContext) {
      Object.assign(this.contentTemplateContext, props);
    } else {
      Object.assign(this.contentComponentRef.instance, props);
      this.contentComponentRef.injector.get(ChangeDetectorRef).markForCheck();
    }
  }

  updatePointerOrigin = (point: Point) => {
    if (this.origin instanceof PointerOverlayOrigin) {
      this.origin.point = point;
      this.updatePosition();
    }
  };

  position = (newPlacement?: string | OverlayPlacement) => {
    if (newPlacement) {
      this.updatePlacement(newPlacement);
    }

    if (this.connected && !this.isMobileView) {
      toggleElement(this.element, this.origin.isVisible);

      if (this.fitStrategy === 'resize') {
        this.resetSize();
      }

      const {placement, ...dimensions} = this.getPositionAndSize();

      if (this.opts.preferInitialPlacement && !this.preferredPlacement) {
        this.preferredPlacement = placement;
      }

      this.currentPlacementSubject.next(placement);

      this.element.style.left = `${dimensions.left}px`;
      this.element.style.top = `${dimensions.top}px`;
      this.element.style.maxWidth = `${dimensions.maxWidth}px`;
      this.element.style.maxHeight = `${dimensions.maxHeight}px`;
    }
  };

  handleTouchMove = (event: TouchEvent) => {
    if (!this.isTouching) {
      this.detachScrollTouchMoveHandler();

      return;
    }

    event.stopPropagation();

    const deltaY = this.touchClientY - event.targetTouches[0].clientY;

    if (deltaY >= 0 || deltaY * -1 >= this.overlayContentHeight) {
      return;
    }

    this.overlayBackgroundEl.nativeElement.style.opacity = String(1 - Math.abs(deltaY) / this.overlayContentHeight);
    this.overlayContentEl.nativeElement.style.transform = `translateY(${deltaY * -1}px)`;
  };

  private attachContent() {
    if (isTemplateContent(this.content)) {
      this.contentTemplateContext = {
        ...(this.opts.props || ({} as Partial<TContentProps>)),
        $implicit: this.overlay,
      };
      this.contentContainer.createEmbeddedView(this.content.templateRef, this.contentTemplateContext);
    } else {
      const component = isComponentContent(this.content) ? this.content.component : this.content;

      this.contentComponentRef = this.contentContainer.createComponent(component);
      Object.assign(this.contentComponentRef.instance, {
        ...this.opts.props,
        overlay: this.overlay,
      });
    }
  }

  private attachScrollHandlers() {
    this.originParents = [];

    this.ngZone.runOutsideAngular(() => {
      let parent = this.origin.element?.parentElement;

      while (parent) {
        this.originParents!.push(parent);
        parent.addEventListener('scroll', this.updatePosition);
        parent = parent.parentElement;
      }

      window.addEventListener('scroll', this.updatePosition);
    });
  }

  private detachScrollHandlers() {
    window.removeEventListener('scroll', this.updatePosition);
    this.originParents?.forEach(element => element.removeEventListener('scroll', this.updatePosition));
  }

  private attachTouchMoveHandler() {
    window.addEventListener('touchmove', this.handleTouchMove);
  }

  private detachScrollTouchMoveHandler() {
    window.removeEventListener('touchmove', this.handleTouchMove);
  }

  private toggleBodyScroll(enable: boolean) {
    if (enable && this.scrollLockedElems) {
      this.scrollLockedElems.forEach(enableBodyScroll);
      this.scrollLockedElems = null;
    } else if (!enable && !this.scrollLockedElems) {
      this.scrollLockedElems = [this.overlayBackgroundEl.nativeElement, this.overlayContentEl.nativeElement];
      this.scrollLockedElems.forEach(elem => disableBodyScroll(elem));
    }
  }

  private setOrigin(origin: OverlayOrigin | null | undefined) {
    if (this.originResizeObserver) {
      this.originResizeObserver.disconnect();
      this.originResizeObserver = null;
    }

    if (origin) {
      this.origin = origin;

      if (origin instanceof ElementOverlayOrigin) {
        this.ngZone.runOutsideAngular(() => {
          this.originResizeObserver = new ResizeObserver(skipInitialCall(this.updatePosition));
          this.originResizeObserver.observe(origin.element);
        });
      }
    }
  }

  private resetPosition() {
    this.preferredPlacement = null;
    this.element.style.left = '';
    this.element.style.top = '';
    this.currentPlacementSubject.next(null);
  }

  private resetSize() {
    this.element.style.maxWidth = `${window.innerWidth}px`;
    this.element.style.maxHeight = `${window.innerHeight}px`;
  }

  private getPositionAndSize(): Position & {maxWidth: number; maxHeight: number; placement: OverlayPlacement} {
    const origin = this.origin.getDimensions();
    const originalOverlay = this.element.getBoundingClientRect();
    const placement: OverlayPlacement = {...(this.preferredPlacement || this.placement)!};

    const overlay = this.getInitialDynamicRect(placement, origin, originalOverlay);
    const {offsetX = 0, offsetY = 0} = placement;

    // Applying offsets
    overlay.top += offsetY;
    overlay.left += offsetX;

    const boundaries = this.getPositionBoundaries(origin);

    // Flipping vertically if necessary
    if (
      overlay.top < boundaries.top &&
      placement.originY === 'top' &&
      placement.overlayY === 'bottom' &&
      boundaries.bottom - origin.bottom > origin.top - boundaries.top
    ) {
      flipPlacement(placement, 'y');
      overlay.top = origin.bottom - offsetY;
    } else if (
      overlay.bottom > boundaries.bottom &&
      placement.originY === 'bottom' &&
      placement.overlayY === 'top' &&
      origin.top - boundaries.top > boundaries.bottom - origin.bottom
    ) {
      flipPlacement(placement, 'y');
      overlay.bottom = origin.top - offsetY;
    }

    // Flipping horizontally if necessary
    if (
      overlay.left < boundaries.left &&
      placement.originX === 'left' &&
      placement.overlayX === 'right' &&
      boundaries.right - origin.right > origin.left - boundaries.left
    ) {
      flipPlacement(placement, 'x');
      overlay.left = origin.right - offsetX;
    } else if (
      overlay.left + overlay.width > boundaries.right &&
      placement.originX === 'right' &&
      placement.overlayX === 'left' &&
      origin.left - boundaries.left > boundaries.right - origin.right
    ) {
      flipPlacement(placement, 'x');
      overlay.right = origin.left - offsetX;
    }

    switch (this.fitStrategy) {
      case 'resize':
        // Resizing
        if (overlay.top < boundaries.top) {
          overlay.height -= boundaries.top - overlay.top;
          overlay.top = boundaries.top;
        }

        if (overlay.bottom > boundaries.bottom) {
          overlay.height -= overlay.bottom - boundaries.bottom;
        }

        if (overlay.left < boundaries.left) {
          overlay.width -= boundaries.left - overlay.left;
          overlay.left = boundaries.left;
        }

        if (overlay.right > boundaries.right) {
          overlay.width -= overlay.right - boundaries.right;
        }

        break;

      case 'shift':
        // Shifting
        overlay.top = Math.max(boundaries.top, Math.min(overlay.top, boundaries.bottom - overlay.height));
        overlay.left = Math.max(boundaries.left, Math.min(overlay.left, boundaries.right - overlay.width));
        break;
    }

    if (this.isAbsolutelyPositioned) {
      const {left, top} = this.overlayContainer.getBoundingClientRect();

      overlay.left -= left;
      overlay.top -= top;
    }

    return {
      top: overlay.top,
      left: overlay.left,
      maxWidth: originalOverlay.width === overlay.width ? window.innerWidth : overlay.width,
      maxHeight: originalOverlay.height === overlay.height ? window.innerHeight : overlay.height,
      placement,
    };
  }

  private getPositionBoundaries(origin: Dimensions): Boundaries {
    if (this.origin instanceof PointerOverlayOrigin) {
      return {
        top: 0,
        right: window.innerWidth,
        bottom: window.innerHeight,
        left: 0,
      };
    }

    if (this.opts.preferVisibility) {
      return {
        top: Math.min(origin.bottom, 0),
        right: Math.max(origin.left, window.innerWidth),
        bottom: Math.max(origin.top, window.innerHeight),
        left: Math.min(origin.right, 0),
      };
    }

    if (this.isWithinFixedElement(this.origin.element)) {
      const scrollableElement = getClosestScrollableContainer(this.origin.element, true);

      if (scrollableElement) {
        const {scrollViewport, scrollTop, scrollLeft, scrollWidth, scrollHeight} = scrollableElement;

        return {
          top: Math.min(scrollViewport.top - scrollTop, 0),
          right: Math.max(scrollViewport.left - scrollLeft + scrollWidth, window.innerWidth),
          bottom: Math.max(scrollViewport.top - scrollTop + scrollHeight, window.innerHeight),
          left: Math.min(scrollViewport.left - scrollLeft, 0),
        };
      }

      return {
        top: 0,
        right: window.innerWidth,
        bottom: window.innerHeight,
        left: 0,
      };
    }

    const documentRect = document.documentElement.getBoundingClientRect();
    const bodyRect = document.body.getBoundingClientRect();

    return {
      top: Math.min(documentRect.top, bodyRect.top),
      right: Math.max(window.innerWidth, documentRect.right, bodyRect.right),
      bottom: Math.max(window.innerHeight, documentRect.bottom, bodyRect.bottom),
      left: Math.min(documentRect.left, bodyRect.left),
    };
  }

  private isWithinFixedElement(elem: HTMLElement | null): boolean {
    while (elem) {
      const position = getComputedStyle(elem).position;

      if (position === 'fixed' || position === 'sticky') {
        return true;
      }

      elem = elem.offsetParent as HTMLElement | null;
    }

    return false;
  }

  private updatePlacement(placement: OverlayPlacement | string | null | undefined) {
    this.placement = typeof placement === 'string' ? convertPlacement(placement) : placement || null;
    this.preferredPlacement = null;
  }

  private getInitialDynamicRect(
    placement: OverlayPlacement,
    origin: Boundaries & Size,
    originalOverlay: DOMRect,
  ): DynamicRect {
    const overlay = new DynamicRect(originalOverlay);

    const {originX, originY, overlayX, overlayY} = placement;

    switch (originX) {
      case 'left':
        overlay.left = origin.left;
        break;
      case 'right':
        overlay.left = origin.right;
        break;
      case 'center':
        overlay.left = origin.left + origin.width / 2;
        break;
    }

    switch (overlayX) {
      case 'right':
        overlay.left -= overlay.width;
        break;
      case 'center':
        overlay.left -= overlay.width / 2;
        break;
    }

    switch (originY) {
      case 'top':
        overlay.top = origin.top;
        break;
      case 'bottom':
        overlay.top = origin.bottom;
        break;
      case 'center':
        overlay.top = origin.top + origin.height / 2;
        break;
    }

    switch (overlayY) {
      case 'bottom':
        overlay.top -= overlay.height;
        break;
      case 'center':
        overlay.top -= overlay.height / 2;
        break;
    }

    return overlay;
  }

  private updateLayout = () => {
    if (this.isMobileView) {
      this.toggleBodyScroll(false);
      this.resetPosition();
      this.resetSize();
    } else {
      this.toggleBodyScroll(true);
      this.position();
    }
  };

  private updatePosition = () => {
    this.position();
  };
}
